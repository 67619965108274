import { useEffect, useState, useRef } from "react";
import "../styling.css";
import person from "./coat.png";
import okey from "./good.gif";
import { v4 as uuidv4 } from "uuid";
import regpic from "./ticket.jpg";

import { initializeApp } from "@firebase/app";
import { getDatabase } from "firebase/database";
import { ref, set, get, update, remove, child } from "firebase/database";
import DotLoader from "react-spinners/DotLoader";
import Loaders from "./Loaders.jsx";
import { toPng } from "html-to-image";
import { QRCodeCanvas } from "qrcode.react";
import QRCodeGenerator from "./QrcodeGenerator.jsx";

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyDULsGioNpdaBgFRJvyV-4wxYTfP7Q9fM4",
    authDomain: "ackcess-a9060.firebaseapp.com",
    projectId: "ackcess-a9060",
    storageBucket: "ackcess-a9060.firebasestorage.app",
    messagingSenderId: "972227509687",
    appId: "1:972227509687:web:a638f28a54d79e2fd8fc32",
    measurementId: "G-N23BVR7PVQ",
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase();
const db = database;
const dbref = ref(db);

export default function Register() {
  let [color, setColor] = useState("#ffffff");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [register, setRegister] = useState(false);
  const [save, setSave] = useState(false);

  const refs = useRef(null);

  function generateSeparatedString() {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()_+-=[]{}|;:,.<>?";

    const getRandom = (chars, length) => {
      return Array.from(
        { length },
        () => chars[Math.floor(Math.random() * chars.length)]
      ).join("");
    };

    const parts = [];
    for (let i = 0; i < 2; i++) {
      parts.push(getRandom(letters, 2));
      parts.push(getRandom(numbers, 2));
    }

    return parts.join("");
  }

  const [data, setData] = useState("");

  const handleChange = (event) => {
    setData(event.target.value);
  };

  function generateUUID() {
    return uuidv4();
  }

  useEffect(() => {
    return () => {};
  }, []);

  const closeModel = () => {
    setRegister(false);
  };

  const submit = (e) => {
    setLoading(true);
    const rnd = generateSeparatedString();

    if (name && email && phone) {
      update(ref(db, `ict/${rnd}`), {
        name: name,
        email: email,
        phone: phone,
      }).then(() => {
        setStatus(" Registration Successful");
        setFlag(true);
        setLoading(false);
        setRegister(true);
        setSave(true);
      });
    } else {
      setStatus("Fill all details");
      setFlag(true);
      setLoading(false);
      setRegister(false);
    }
  };
  const handleDownload = (event) => {
    // Prevent default behavior (e.g., page refresh)
    event.preventDefault();

    if (refs.current) {
      toPng(refs.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "Ict.png";
          link.click();
        })
        .catch((err) => {
          console.error("Error generating image", err);
        });
    }
  };

  return (
    <div className="loginpages">
      <div className="crc"></div>
      <form className="loginss" onSubmit={submit}>
        <div className="profimgs">
          <img src={person} alt="" width="80" height="80" />
        </div>
        <div className="dso">
          <p> PDPC</p>
        </div>

        {register && (
          <div className="staaa">
            <p
              style={{
                color: "white",
              }}
            >
              {status}
            </p>{" "}
            <div className="star" onClick={closeModel}>
              {/* <img src={okey} alt="" width="30" height="30" />
               */}
              <p>close</p>
            </div>
          </div>
        )}
        {save && (
          <div className="regist">
            <div ref={refs} className="regpic">
              <img src={regpic} alt="" />
              <p className="nameee">{name}</p>
              <p className="namee">{phone}</p>
              <p className="namse">{email}</p>
              <div className="qrcode">
                <div
                  style={{
                    marginTop: "-5px",
                    background: "white",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <QRCodeCanvas
                    value={generateSeparatedString()}
                    size={100} // size of the QR code
                    bgColor={"#ffffff"} // background color
                    fgColor={"#000000"} // foreground color
                    level={"H"} // error correction level (L, M, Q, H)
                  />
                </div>
              </div>

              <button className="download" onClick={handleDownload}>
                Download
              </button>
            </div>
          </div>
        )}

        {!flag && (
          <div className="loginins">
            <div className="inputs">
              <label>Full Name</label>

              <input
                id="name"
                name="name"
                value={name}
                placeholder="Full Name "
                className="mt-1 block w-full"
                autoComplete="name"
                isFocused={true}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="inputs">
              <label>Phone Number</label>

              <input
                type="text"
                placeholder="Phone Number"
                value={phone}
                className="mt-1 block w-full"
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>

            <div className="inputs">
              <label>Email </label>

              <input
                id="email"
                type="email"
                name="email"
                value={email}
                placeholder="Email Address "
                className="mt-1 block w-full"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* <div className="inputs">
            <label>Organization & Designation</label>

            <input
              type="text"
              name="email"
              value={company}
              placeholder="Organization"
              className="mt-1 block w-full"
              autoComplete="email"
              onChange={(e) => setCompany(e.target.value)}
              required
            />
          </div> */}

            {/* <div className="inputs">
            <label>Position</label>

            <input
              type="text"
              name="title"
              value={title}
              placeholder="Title"
              className="mt-1 block w-full"
              autoComplete="email"
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div> */}
          </div>
        )}

        {loading ? (
          <div className="spin">
            {" "}
            <DotLoader
              color={color}
              loading={loading}
              // cssOverride={override}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <></>
        )}

        <div className="logbtn" onClick={submit}>
          Register
        </div>

        <div
          style={{
            fontSize: "0.55em",
            color: "#cccccc",
          }}
        >
          <p>ict-reg@2024</p>
        </div>
      </form>
    </div>
  );
}
