import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Launch from "./Components/Launch/launch";
import Chat from "./Components/Chat/chat";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/all" element={<Chat />} />
        <Route path="/" element={<Launch />} />
        <Route path="*" element={<Launch />} />
      </Routes>
    </Router>
  );
}

export default App;
